<template>
    <div>
        <template v-if="state.isFranchise">
            <p class="mt-4 mb-4">
                Hieronder zie je de productvoorraden die gewijzigd zijn:
            </p>
            <table class="location-confirmation__table">
                <tr>
                    <td>#</td>
                    <td>Product</td>
                    <td>Nieuw</td>
                    <td>Retour</td>
                    <td>Verkoopprijs</td>
                </tr>
                <tr v-for="stockChange in state.stockChangesToday">
                    <td>{{ stockChange.product.identifier }}</td>
                    <td>{{ stockChange.product.name }}</td>
                    <td>{{ stockChange.new_delivered }}</td>
                    <td>{{ stockChange.loss }}</td>
                    <td>€{{ stockChange.product.sellingprice }}</td>
                </tr>
            </table>
            <p class="mt-4 mb-4 text--extra-small">
                Dit filiaal is een franchise.
                <br />
                Laat de vestigingsmanager een handtekening zetten voor de
                geleverde en geretourneerde producten.
            </p>
            <Vue3Signature ref="signature1" :w="'100%'" :h="'400px'" class="signature__container"></Vue3Signature>
            <button class="mt-4 button button--primary mb-4" @click="clearSignature">Verwijder handtekening</button>
            <p class="text--extra-small text--error mb-4" v-if="state.mustFillSignatureError">{{
            state.mustFillSignatureError }}</p>
        </template>
        <div class="mb-4">
            <p v-if="allProductsFilled">Alle producten zijn ingevoerd.</p>
            <p v-else>
                Er zijn {{ amountFilledProducts }} van de {{ amountProductsTotal }} producten ingevoerd.
                Klopt dit?
            </p>
            <p class="mt-4">
                Maak foto's om de voorraadwijzigingen te bevestigen
            </p>
        </div>

        <p class="mt-4 mb-4">Bloemen</p>
        <media-library-attachment @is-ready-to-submit-change="state.readyToSubmitFlower = $event" name="flowerImage1"
            @change="onChangeMedia('flowerMedia', 0, $event)" />
        <media-library-attachment @is-ready-to-submit-change="state.readyToSubmitFlower = $event" name="flowerImage2"
            @change="onChangeMedia('flowerMedia', 1, $event)" />

        <p class="mt-4 mb-4">Planten</p>
        <media-library-attachment @is-ready-to-submit-change="state.readyToSubmitPlant = $event" name="plantImage1"
            @change="onChangeMedia('plantMedia', 0, $event)" />
        <media-library-attachment @is-ready-to-submit-change="state.readyToSubmitPlant = $event" name="plantImage2"
            @change="onChangeMedia('plantMedia', 1, $event)" />

        <button class="mt-4 button button--primary" @click="submitForm"
            :class="!allowedToSubmit ? 'button--disabled' : ''">
            Uploaden & Bevestigen
        </button>
    </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from 'vue';
import apiClient from '@services/apiClient';
import { MediaLibraryAttachment } from '@flarone/media-library-pro-vue3-attachment';
import router from "../../router";

export default {
    name: "LocationConfirmation",
    props: {
        products: Object,
        locationId: String,
        isFranchise: Number,
        onSubmitSucces: Function,
    },
    components: {
        MediaLibraryAttachment
    },
    setup(props, { emit }) {
        const state = reactive({
            flowerMedia: [null, null], // Now an array for two images
            plantMedia: [null, null], // Now an array for two images
            readyToSubmitPlant: false,
            readyToSubmitFlower: false,
            isProcessing: false,
            isFranchise: props.isFranchise,
            mustFillSignatureError: null,
            count: 0,
            stockChangesToday: []
        })

        onMounted(() => {
            apiClient.get(`/location/${props.locationId}/stockChangesToday`).then((resp) => {
                state.stockChangesToday = resp.data
            })
        })

        const onChangeMedia = (type, index, media) => {
            media = Object.keys(media).length === 0 ? null : media;
            state[type][index] = media;
        }

        const submitForm = () => {
            if (state.isFranchise) {
                if (signatureIsEmpty()) {
                    state.mustFillSignatureError = "Omdat dit een franchiser is, moet je een handtekening laten zetten door de vestigingsmanager!"
                    return;
                }
            }

            if (allowedToSubmit.value && !state.isProcessing) {
                state.isProcessing = true
                let data = {
                    flowerMedia: state.flowerMedia,
                    plantMedia: state.plantMedia,
                    signature: state.isFranchise ? signature1.value.save("image/jpeg") : null
                }

                apiClient.post(`/location/${props.locationId}/confirm`, data).then((resp) => {
                    router.push('/')
                })
            }
        }

        const allowedToSubmit = computed(() => {
            const flowerMediaReady = state.flowerMedia.some(m => m !== null);
            const plantMediaReady = state.plantMedia.some(m => m !== null);
            const allMediaSubmitted = state.readyToSubmitPlant && state.readyToSubmitFlower;
            return flowerMediaReady && plantMediaReady && allMediaSubmitted;
        })

        const signature1 = ref(null)

        const clearSignature = () => {
            signature1.value.clear()
        }

        const signatureIsEmpty = () => {
            return signature1.value.isEmpty()
        }

        return {
            onChangeMedia,
            state,
            allowedToSubmit,
            submitForm,
            clearSignature,
            signature1
        };
    }
}
</script>

<style lang="scss" scoped>
.location-confirmation {
    &__table {
        width: 100%;

        tr {
            border-bottom: 1px solid black;
        }

        td {
            font-size: 1.4rem;
            padding: .25rem .5rem;
        }
    }
}

.signature {
    &__container {
        border: .2rem solid;
    }
}
</style>
