import './bootstrap';
import {createApp} from "vue";
import {createPinia} from "pinia";
import App from "./App.vue";
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {createHead} from '@vueuse/head'
import apiClient from '@services/apiClient'
import '@flarone/media-library-pro-styles/dist/styles.css'
import VueEasyLightbox  from "vue-easy-lightbox";
import Vue3Signature from "vue3-signature";
//Icons
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {
    faXmark,
    faArrowRightFromBracket,
    faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons";

library.add(faXmark, faArrowRightFromBracket, faWifiSlash);

const pinia = createPinia()
const app = createApp({});
const head = createHead({});

apiClient.get('/user').then((resp) => {
    app.provide('user', resp.data)
    app.component('app', App);
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.use(Vue3Signature);
    app.use(router);
    app.use(VueEasyLightbox);
    app.use(head);
    app.use(pinia);
    app.use(VueAxios, axios);

    app.mount('#app');
})
